import { createSlice } from '@reduxjs/toolkit';

import mockStatisticsData from "src/mock-data/mockStatisticsData";

const initialState = {
  data: mockStatisticsData,
};

const statisticsSlice = createSlice({
  name: 'statistics',
  initialState,
  reducers: {},
});

export default statisticsSlice.reducer;
