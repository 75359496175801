import { configureStore } from '@reduxjs/toolkit'

import statisticsReducer from './statisticsSlice'
import settingsReducer from './settingsSlice'

const store = configureStore({
  reducer: {
    statistics: statisticsReducer,
    settings: settingsReducer,
  },
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
})

export default store

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
