import React, { useEffect, FC } from 'react'
import { Route, Routes } from 'react-router-dom'
import { ConfigProvider, Layout, theme } from 'antd'
import enUS from 'antd/locale/en_US'
import heIL from 'antd/locale/he_IL'
import { initializeApp } from 'firebase/app'
import { GoogleAuthProvider, getAuth } from 'firebase/auth'

import { setDefaultSettings } from './store/settingsSlice'
import { useAppDispatch, useAppSelector } from 'src/store/hooks'
import firebaseConfig from 'src/utils/firebaseConfig'
import PrivateRoute from 'src/components/PrivateRoute'
import Header from 'src/components/Header'
import LoginPage from 'src/pages/Login'
import MainPage from 'src/pages/Main'
import NotFoundPage from 'src/pages/NotFound'

import styles from './App.module.scss'

const app = initializeApp(firebaseConfig)
export const auth = getAuth(app)

export const provider = new GoogleAuthProvider()

const { defaultAlgorithm, darkAlgorithm } = theme

const App: FC = () => {
  const dispatch = useAppDispatch()
  const { theme, language } = useAppSelector(state => state.settings)

  useEffect(() => {
    dispatch(setDefaultSettings())
  }, [])

  return (
    <ConfigProvider
      theme={{ algorithm: theme === 'dark' ? darkAlgorithm : defaultAlgorithm }}
      direction={language === 'en' ? 'ltr' : 'rtl'}
      locale={language === 'en' ? enUS : heIL}
    >
      <Layout>
        <Header />
        <Layout className={styles.container}>
          <Routes>
            <Route path="/login" element={<LoginPage />} />
            <Route
              path="/main"
              element={
                <PrivateRoute>
                  <MainPage />
                </PrivateRoute>
              }
            />
            <Route
              path="/"
              element={
                <PrivateRoute>
                  <MainPage />
                </PrivateRoute>
              }
            />
            <Route path="*" element={<NotFoundPage />} />
          </Routes>
        </Layout>
      </Layout>
    </ConfigProvider>
  )
}

export default App
