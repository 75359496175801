import React, { FC } from 'react'
import { Navigate } from 'react-router-dom'

import { token } from 'src/utils/localStorageData'
import { useAppSelector } from 'src/store/hooks'

interface IProps {
  children: any
}

const PrivateRoute: FC<IProps> = ({ children }) => {
  const { isSignedOut, isAuth } = useAppSelector(state => state.settings)

  if (isSignedOut || (!isAuth && !token)) {
    return <Navigate to="/login" />
  }

  return children
}

export default PrivateRoute
