import React, { FC } from 'react'
import { Button, Col, Dropdown, Row, Space, Typography } from 'antd'
import { DownOutlined } from '@ant-design/icons'
import { Line } from '@ant-design/plots'
import { useTranslation } from 'react-i18next'

import { useAppSelector } from 'src/store/hooks'
import chartConfig from './config'
import items from './menuItems'

import styles from './index.module.scss'

const { Title } = Typography

const Chart: FC = () => {
  const { data } = useAppSelector(state => state.statistics)
  const { t } = useTranslation()

  const config = {
    data,
    ...chartConfig,
  }

  return (
    <div className={styles.chart}>
      <Row className={styles.interval_section}>
        <Col>
          <Title level={3}>{t('chart.title')}</Title>
        </Col>
        <Col>
          <Dropdown menu={{ items }}>
            <Button className={styles.interval_button}>
              <Space>
                {t('chart.interval')}
                <DownOutlined />
              </Space>
            </Button>
          </Dropdown>
        </Col>
      </Row>
      <div className={styles.chart_wrapper}>
        <Line autoFit {...config} />
      </div>
    </div>
  )
}

export default Chart
