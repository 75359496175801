import React, { FC } from 'react'
import { Avatar, Button, Layout, Select, Space, Switch, Typography } from 'antd'
import { LogoutOutlined } from '@ant-design/icons'

import useCustomTranslation from 'src/hooks/useCustomTranslation'
import { useAppDispatch, useAppSelector } from 'src/store/hooks'
import { signOutUser, toggleTheme } from 'src/store/settingsSlice'
import { ReactComponent as LightThemeIcon } from 'src/assets/icons/light_mode_white_24dp.svg'
import { ReactComponent as DarkThemeIcon } from 'src/assets/icons/dark_mode_white_24dp.svg'

import styles from './index.module.scss'

const { Header: AntdHeader } = Layout
const { Text } = Typography

const Header: FC = () => {
  const dispatch = useAppDispatch()
  const { isAuth, theme, userData } = useAppSelector(state => state.settings)
  const { DEFAULT_SELECTED_VALUE, values, handleChange } =
    useCustomTranslation()

  const isDarkTheme = theme === 'dark'

  const handleSignOut = () => {
    dispatch(signOutUser())
  }

  return (
    <AntdHeader
      className={`${styles.header} ${
        theme === 'light' ? styles.header_light : ''
      }`}
    >
      <Select
        placeholder={DEFAULT_SELECTED_VALUE}
        options={values}
        onChange={handleChange}
        popupClassName={styles.popup}
      />
      {isAuth ? (
        <Space align="center">
          {userData?.photoURL ? (
            <Avatar src={userData?.photoURL} alt="Profile image" />
          ) : undefined}
          <Text>{userData?.displayName || userData?.email}</Text>
          <Button type="link">
            <LogoutOutlined onClick={handleSignOut} />
          </Button>
        </Space>
      ) : (
        <></>
      )}
      <Switch
        onClick={() => dispatch(toggleTheme())}
        checkedChildren={<DarkThemeIcon />}
        unCheckedChildren={<LightThemeIcon />}
        checked={isDarkTheme}
      />
    </AntdHeader>
  )
}

export default Header
