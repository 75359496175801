import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Button, Card, Col, DatePicker, Row, Typography } from 'antd'
import { RightOutlined } from '@ant-design/icons'

import styles from './index.module.scss'

const { Paragraph, Title } = Typography

const ControlPanel: FC = () => {
  const { t } = useTranslation()

  return (
    <>
      <Title level={3} className={styles.title}>
        {t('title')}
      </Title>
      <Card hoverable size="small">
        <Row justify={'space-between'} gutter={[8, 16]}>
          <Col sm={12} md={9}>
            <Paragraph>{t('control_panel.start_date')}</Paragraph>
            <DatePicker format="MMMM D, YYYY" />
          </Col>
          <Col sm={12} md={9}>
            <Paragraph>{t('control_panel.end_date')}</Paragraph>
            <DatePicker format="MMMM D, YYYY" />
          </Col>
          <Col sm={24} xs={24} md={6} className={styles.report_button_wrapper}>
            <Button className={styles.report_button} type="primary">
              {t('control_panel.view_report')}
              <RightOutlined />
            </Button>
          </Col>
        </Row>
      </Card>
    </>
  )
}

export default ControlPanel
