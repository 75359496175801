import i18next from 'i18next'
import { initReactI18next } from 'react-i18next'

import translationEnglish from 'src/locales/en/translation.json'
import translationHebrew from 'src/locales/he/translation.json'

i18next.use(initReactI18next).init({
  resources: {
    en: {
      translation: translationEnglish,
    },
    he: {
      translation: translationHebrew,
    },
  },
})
