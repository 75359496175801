const chartConfig = {
  xField: 'date',
  yField: 'value',
  seriesField: 'type',
  yAxis: {
    label: {
      // format number to thousand
      formatter: (v: any) =>
        `${v}`.replace(/\d{1,3}(?=(\d{3})+$)/g, s => `${s},`),
    },
  },
  color: ['#4c77b3', '#ff1c1c'],
}

export default chartConfig
