import React from 'react'

import { LanguageType, changeLanguage } from 'src/store/settingsSlice'
import { useAppDispatch, useAppSelector } from 'src/store/hooks'
import { ReactComponent as USFlag } from 'src/assets/icons/US_flag.svg'
import { ReactComponent as ILFlag } from 'src/assets/icons/IL_flag.svg'

const values = [
  {
    value: 'en',
    label: <USFlag />,
  },
  {
    value: 'he',
    label: <ILFlag />,
  },
]

const useCustomTranslation = () => {
  const dispatch = useAppDispatch()
  const { language } = useAppSelector(state => state.settings)

  const DEFAULT_SELECTED_VALUE =
    language === 'en' ? values[0].label : values[1].label

  const handleChange = (value: LanguageType) => {
    dispatch(changeLanguage(value))
  }

  return { DEFAULT_SELECTED_VALUE, values, handleChange }
}

export default useCustomTranslation
