import React, { FC } from 'react'
import { Col, Row } from 'antd'
import {
  BarChartOutlined,
  RightCircleOutlined,
  ShoppingCartOutlined,
} from '@ant-design/icons'
import { useTranslation } from 'react-i18next'

import StatisticsCard from 'src/components/StatisticsCard'

const SecondRow: FC = () => {
  const { t } = useTranslation()

  return (
    <Row gutter={[24, 24]} justify="space-between">
      <Col xs={24} sm={8}>
        <StatisticsCard
          amount={75100}
          title={t('statistics_cards.total_revenue')}
          amountType="currency"
          icon={<ShoppingCartOutlined />}
        />
      </Col>
      <Col xs={24} sm={8}>
        <StatisticsCard
          amount={16}
          amountType="currency"
          title={t('statistics_cards.average_payment')}
          icon={<BarChartOutlined />}
        />
      </Col>
      <Col xs={24} sm={8}>
        <StatisticsCard
          amount={15}
          amountType="percent"
          title={t('statistics_cards.repeat_purchase_rate')}
          icon={<RightCircleOutlined />}
        />
      </Col>
    </Row>
  )
}

export default SecondRow
