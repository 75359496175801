import { MenuProps } from 'antd'

const menuItems: MenuProps['items'] = [
  {
    label: 'Revenue',
    key: '1',
  },
  {
    label: 'Sales',
    key: '2',
  },
]

export default menuItems
