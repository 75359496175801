import React, { FC } from 'react'
import { Navigate } from 'react-router-dom'
import { Button, Card, Divider, Form, Input, Layout } from 'antd'
import { GoogleOutlined } from '@ant-design/icons'
import { useTranslation } from 'react-i18next'
import { signInWithEmailAndPassword, signInWithPopup } from 'firebase/auth'

import useFieldRules from 'src/hooks/useFieldRules'
import { signInUser } from 'src/store/settingsSlice'
import { useAppDispatch, useAppSelector } from 'src/store/hooks'
import { auth, provider } from 'src/App'

import styles from './index.module.scss'

interface FieldType {
  email?: string
  password?: string
}

const LoginPage: FC = () => {
  const dispatch = useAppDispatch()
  const { t } = useTranslation()
  const [form] = Form.useForm()
  const { isSignedIn } = useAppSelector(state => state.settings)
  const { emailRules, passwordRules } = useFieldRules()

  const handleSubmit = async ({ email, password }: any) => {
    try {
      await form.validateFields()
      const userCredential = await signInWithEmailAndPassword(
        auth,
        email,
        password
      )
      dispatch(signInUser(userCredential.user))
    } catch (error) {
      console.error(error)
    }
  }

  const handleGoogleSignIn = async () => {
    try {
      const userCredential = await signInWithPopup(auth, provider)
      dispatch(signInUser(userCredential.user))
    } catch (error) {
      console.error(error)
    }
  }

  return isSignedIn ? (
    <Navigate to="/main" replace />
  ) : (
    <Layout className={styles.wrapper}>
      <Card>
        <Form
          form={form}
          name="login-form"
          layout="vertical"
          onFinish={handleSubmit}
        >
          <Form.Item<FieldType>
            label={t('login.email_label')}
            name="email"
            rules={emailRules}
          >
            <Input />
          </Form.Item>

          <Form.Item<FieldType>
            label={t('login.password_label')}
            name="password"
            rules={passwordRules}
          >
            <Input.Password />
          </Form.Item>

          <Form.Item>
            <Button type="link">
              {t('login.forgot_password_button_text')}
            </Button>
          </Form.Item>

          <Form.Item>
            <Button type="primary" htmlType="submit" block>
              {t('login.submit_button_text')}
            </Button>
          </Form.Item>

          <Divider>{t('login.divider_text')}</Divider>

          <Form.Item>
            <Button
              onClick={handleGoogleSignIn}
              icon={<GoogleOutlined />}
              block
            >
              {t('login.google_auth_button_text')}
            </Button>
          </Form.Item>
        </Form>
      </Card>
    </Layout>
  )
}

export default LoginPage
