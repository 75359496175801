import { FormRule } from 'antd'
import { useTranslation } from 'react-i18next'

const passwordRegex =
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@#$%^&+=!])[A-Za-z\d@#$%^&+=!]+$/

const useFieldRules = () => {
  const { t } = useTranslation()

  const emailRules: FormRule[] = [
    {
      type: 'email',
      required: true,
      message: t('login.empty_email_error_message'),
    },
  ]

  const passwordRules: FormRule[] = [
    {
      required: true,
      message: t('login.empty_password_error_message'),
    },
    {
      min: 8,
      message: t('login.invalid_password_length_error_message'),
    },
    {
      pattern: passwordRegex,
      message: t('login.invalid_password_content_error_message'),
    },
  ]
  return { emailRules, passwordRules }
}

export default useFieldRules
