import React, { FC } from 'react'
import { Card, Col, Row, Statistic } from 'antd'

import styles from './index.module.scss'

interface IProps {
  amount: number
  amountType: 'currency' | 'percent'
  title: string
  icon: React.ReactNode
}

const StatisticsCard: FC<IProps> = ({ amount, title, amountType, icon }) => {
  return (
    <Card hoverable size="small" className={styles.card}>
      <Row
        align={'middle'}
        justify="space-between"
        className={styles.card_content}
      >
        <Col span={12} className={styles.icon}>
          {icon}
        </Col>
        <Col span={12}>
          <Statistic
            title={title}
            value={amount}
            precision={2}
            prefix={amountType === 'currency' ? '$' : undefined}
            suffix={amountType === 'percent' ? '%' : undefined}
          />
        </Col>
      </Row>
    </Card>
  )
}

export default StatisticsCard
