const mockStatisticsData = [
  {
    date: '2018/8/1',
    type: 'Revenue',
    value: 257,
  },
  {
    date: '2018/8/2',
    type: 'Sales',
    value: 508,
  },
  {
    date: '2018/8/3',
    type: 'Revenue',
    value: 2916,
  },
  {
    date: '2018/8/4',
    type: 'Sales',
    value: 289,
  },
  {
    date: '2018/8/5',
    type: 'Revenue',
    value: 6268,
  },
  {
    date: '2018/8/6',
    type: 'Sales',
    value: 4512,
  },
  {
    date: '2018/8/6',
    type: 'Revenue',
    value: 428,
  },
  {
    date: '2018/8/7',
    type: 'Sales',
    value: 6411,
  },
  {
    date: '2018/8/8',
    type: 'Revenue',
    value: 412,
  },
  {
    date: '2018/8/9',
    type: 'Sales',
    value: 661,
  },
  {
    date: '2018/8/10',
    type: 'Revenue',
    value: 7731,
  },
]

export default mockStatisticsData;
