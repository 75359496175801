import React, { FC } from 'react'
import { Layout } from 'antd'

import FirstRow from 'src/components/Rows/FirstRow'
import SecondRow from 'src/components/Rows/SecondRow'
import ThirdRow from 'src/components/Rows/ThirdRow'

import styles from './index.module.scss'

const MainPage: FC = () => {
  return (
    <Layout className={styles.main}>
      <FirstRow />
      <SecondRow />
      <ThirdRow />
    </Layout>
  )
}

export default MainPage
