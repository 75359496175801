import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import i18next from 'i18next'
import { signOut } from 'firebase/auth'
import {
  doc,
  getDoc,
  getFirestore,
  setDoc,
  deleteDoc,
} from 'firebase/firestore'

import { auth } from 'src/App'
import getCurrentColorScheme from 'src/utils/getCurrentColorScheme'
import { ls, token } from 'src/utils/localStorageData'

type ThemeType = 'light' | 'dark'
export type LanguageType = 'en' | 'he'

interface UserData {
  email: string
  displayName: string
  photoURL: string
}

interface SettingsState {
  userData?: UserData | undefined
  theme: ThemeType | undefined
  language: LanguageType | undefined
  isAuth: boolean | undefined
  isSignedIn: boolean | undefined
  isSignedOut: boolean | undefined
  isLoading: boolean
  error: string | undefined
}

const initialState: SettingsState = {
  userData: undefined,
  theme: undefined,
  language: undefined,
  isSignedIn: undefined,
  isSignedOut: undefined,
  isAuth: undefined,
  isLoading: false,
  error: undefined,
}

const firestore = getFirestore()

const userDataDoc = doc(firestore, 'settings/userData')
const themeDoc = doc(firestore, 'settings/theme')
const languageDoc = doc(firestore, 'settings/language')

export const signInUser = createAsyncThunk<any, any, { rejectValue: any }>(
  'settings/signInUser',
  async (payload, { rejectWithValue }) => {
    const { email, displayName, photoURL, uid } = payload

    const token = uid
    const userData = {
      email,
      displayName,
      photoURL,
    }

    try {
      await setDoc(userDataDoc, userData)
    } catch (error) {
      rejectWithValue(error)
    }

    return { userData, token }
  }
)

export const signOutUser = createAsyncThunk<
  undefined,
  undefined,
  { rejectValue: any }
>('settings/signOutUser', async (_, { rejectWithValue }) => {
  try {
    await signOut(auth)
    await deleteDoc(userDataDoc)
  } catch (error) {
    return rejectWithValue(error)
  }
})

export const setDefaultSettings = createAsyncThunk<
  any,
  undefined,
  { rejectValue: any }
>('settings/setDefaultSettings', async (_, { rejectWithValue }) => {
  try {
    const state: SettingsState = { ...initialState }
    const userData = await getDoc(userDataDoc)
    const currentTheme = await getDoc(themeDoc)
    const currentLanguage = await getDoc(languageDoc)
    let lang: LanguageType

    if (currentTheme.exists()) {
      state.theme = currentTheme.data().theme
    } else {
      await setDoc(themeDoc, { theme: getCurrentColorScheme() })
      state.theme = getCurrentColorScheme()
    }

    if (currentLanguage.exists()) {
      lang = currentLanguage.data().language
    } else {
      await setDoc(languageDoc, { language: 'en' })
      lang = 'en'
    }

    i18next.changeLanguage(lang)
    document.documentElement.lang = lang
    state.language = lang
    state.isAuth = true

    if (token) {
      if (userData.exists()) {
        state.userData = userData.data() as UserData
      }
    } else {
      state.isAuth = false
    }

    return state
  } catch (error) {
    rejectWithValue(error)
  }
})

export const toggleTheme = createAsyncThunk<
  any,
  undefined,
  { rejectValue: any }
>('settings/toggleTheme', async (_, { rejectWithValue }) => {
  try {
    const currentTheme = await getDoc(themeDoc)
    const newTheme: ThemeType =
      currentTheme.data()?.theme === 'light' ? 'dark' : 'light'

    await setDoc(themeDoc, { theme: newTheme })
    return newTheme
  } catch (error) {
    return rejectWithValue(error)
  }
})

export const changeLanguage = createAsyncThunk<
  LanguageType,
  LanguageType,
  { rejectValue: any }
>('settings/changeLanguage', async (payload, { rejectWithValue }) => {
  try {
    await setDoc(languageDoc, { language: payload })
    return payload
  } catch (error) {
    return rejectWithValue(error)
  }
})

const settingsSlice = createSlice({
  name: 'settings',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(setDefaultSettings.pending, state => {
        state.isLoading = true
      })
      .addCase(setDefaultSettings.fulfilled, (_, action) => {
        return { ...action.payload, isLoading: false }
      })
      .addCase(setDefaultSettings.rejected, (state, action) => {
        state.error = action.payload
        state.isLoading = false
      })

      .addCase(signInUser.pending, state => {
        state.isLoading = true
      })
      .addCase(signInUser.fulfilled, (state, action) => {
        ls.set('token', action.payload.token)
        state.userData = action.payload.userData
        state.isAuth = true
        state.isSignedIn = true
        state.isSignedOut = false
        state.isLoading = false
      })
      .addCase(signInUser.rejected, (state, action) => {
        state.error = action.payload
        state.isLoading = false
      })

      .addCase(signOutUser.pending, state => {
        state.isLoading = true
      })
      .addCase(signOutUser.fulfilled, state => {
        ls.remove('token')
        state.userData = undefined
        state.isSignedOut = true
        state.isSignedIn = false
        state.isAuth = false
        state.isLoading = false
      })
      .addCase(signOutUser.rejected, (state, action) => {
        state.error = action.payload
        state.isLoading = false
      })

      .addCase(toggleTheme.pending, state => {
        state.isLoading = true
      })
      .addCase(toggleTheme.fulfilled, (state, action) => {
        state.theme = action.payload
        state.isLoading = false
      })
      .addCase(toggleTheme.rejected, (state, action) => {
        state.error = action.payload
        state.isLoading = false
      })

      .addCase(changeLanguage.pending, state => {
        state.isLoading = true
      })
      .addCase(changeLanguage.fulfilled, (state, action) => {
        i18next.changeLanguage(action.payload)
        document.documentElement.lang = action.payload
        state.language = action.payload
        state.isLoading = false
      })
      .addCase(changeLanguage.rejected, (state, action) => {
        state.error = action.payload
        state.isLoading = false
      })
  },
})

export default settingsSlice.reducer
